<template>
    <div id="search-page">
        <div class="search-page__search-bar flex items-center">
          <b-form-input icon-no-border  @keyup.enter.native="searchUser()" placeholder="Digite nome de usuário" v-model="searchQuery" class="input-rounded-full" icon="icon-search" icon-pack="feather" />
        </div>
        <div class="search-meta flex flex-wrap justify-between mt-6">
            <span class="mb-4">{{searchResults.length.toString()}} Resultados</span>
        </div>

        <!-- SEARCH RESULTS -->
        <div class="vx-row mt-4 md:flex-row flex-col-reverse">
            <div class="vx-col md:w-full lg:w-full w-full">
                <b-card class="search-page__search-results lg:p-2">
                    <OneLineDataResultPlatUsers  @callReload="reload" :lines="searchResults"></OneLineDataResultPlatUsers>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import OneLineDataResultPlatUsers from '@/components/one-line-data-result/OneLineDataResultPlatUsers.vue'
import {BCard, BDropdownItem, BFormInput} from 'bootstrap-vue'
export default{
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
    }
  },
  computed: {},
  methods: {
    searchUser() {
        this.$router.replace({ query: { u: this.searchQuery } })
        this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/userSearch',{
          name: this.searchQuery,
        })
        .then(res => {
            this.searchResults = res.data
            console.log(res.data)
        })
    },
    reload(){
      if(this.$route.query.u){
        this.searchQuery = this.$route.query.u;
        this.searchUser();
      }
    }
  },
  components: {
    'OneLineDataResultPlatUsers' : OneLineDataResultPlatUsers,
    BCard,BFormInput
  },
  created() {
    this.reload();
  }
}
</script>


